import { dispatch } from "./observer";

import { handler as classToggler } from "./class-toggler";
import { listenOnce } from "./util";

export const removeVideo = (button, e) => {
  e.preventDefault();
  const TARGET_PARENT = document.querySelector(".image-text--video");
  const target = document.querySelector(button.getAttribute("data-target"));
  target.classList.remove("is-visible");
  TARGET_PARENT.classList.remove("has-active-video");

  // Stop Video
  dispatch("AVP_PAUSE");

  // Reset Video progress
  dispatch("AVP_UPDATE_VIDEO_PROGRESS", {
    timestamp: Number(0),
  });
};

export const playVideo = (button, e) => {
  e.preventDefault();
  const TARGET_PARENT = button.closest("[data-play-video-container='true']");

  TARGET_PARENT.classList.add("has-active-video");
  classToggler(button, e);

  // Play the video when the overlay opens
  dispatch("AVP_PLAY");

  // Close the video overlay on Escape
  listenOnce(document, "keydown", (event) => {
    if (event.key === "Escape") {
      removeVideo(button, event);
    }
  });
};
